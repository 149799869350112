import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M17.09 4.56008C16.39 3.53008 15.59 2.57008 14.69 1.71008C14.34 1.37008 13.75 1.69008 13.85 2.17008C14.04 3.11008 14.24 4.35008 14.24 5.46008C14.24 7.52008 12.89 9.19008 10.83 9.19008C9.29 9.19008 8.03 8.26008 7.48 6.93008C7.38 6.73008 7.34 6.61008 7.28 6.39008C7.17 5.97008 6.62 5.84008 6.38 6.21008C6.2 6.48008 6.03 6.75008 5.87 7.04008C4.68 9.08008 4 11.4601 4 14.0001C4 18.4201 7.58 22.0001 12 22.0001C16.42 22.0001 20 18.4201 20 14.0001C20 10.5101 18.92 7.27008 17.09 4.56008ZM11.71 19.0001C9.93 19.0001 8.49 17.6001 8.49 15.8601C8.49 14.2401 9.54 13.1001 11.3 12.7401C12.77 12.4401 14.28 11.8101 15.33 10.8201C15.61 10.5601 16.07 10.6801 16.15 11.0501C16.38 12.0701 16.5 13.1301 16.5 14.2001C16.51 16.8501 14.36 19.0001 11.71 19.0001Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;