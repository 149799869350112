import React from "react";

const Icon: React.FC = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path className="pathWithStroke" d="M16.0119 4H4.71225C4.45685 4 4.2301 4.16044 4.14933 4.3983L4.03045 4.74842C4.00243 4.83093 3.99359 4.91856 4.00458 5.00487L4.28457 7.2038V8.42996C4.28457 8.72678 4.51192 8.97613 4.81242 9.00891L6.89932 9.23654C6.93619 9.24057 6.97336 9.24118 7.01035 9.23839L8.77999 9.10476C9.37188 9.06006 9.65643 9.8019 9.18152 10.1516L8.47191 10.674C8.44945 10.6906 8.42821 10.7086 8.40836 10.7281L5.80273 13.2861C5.77962 13.3088 5.75846 13.3333 5.73946 13.3594L4.3951 15.207C4.32322 15.3058 4.28457 15.4242 4.28457 15.5456V19.1952C4.28457 19.3497 4.34709 19.4979 4.45837 19.6071L4.66202 19.8071C4.79753 19.9401 4.99013 20.0007 5.17915 19.9697L11.8911 18.8716C11.9621 18.86 12.0345 18.8611 12.105 18.8749L17.7776 19.9887C17.8789 20.0086 17.9837 20.0022 18.0816 19.9701L19.3733 19.5474C19.5818 19.4792 19.7348 19.3034 19.771 19.0906L19.9919 17.7891C20.0032 17.7229 20.0027 17.6552 19.9904 17.5891L19.4935 14.9059C19.4471 14.6556 19.2405 14.4637 18.9833 14.4321L17.0961 14.2006C17.0602 14.1961 17.0239 14.195 16.9877 14.1971L13.4952 14.3987C12.9584 14.4297 12.6598 13.7999 13.0296 13.4166L17.2088 9.08597C17.2934 8.99838 17.3481 8.88718 17.3655 8.76773L17.8716 5.28939C17.9078 5.04118 17.778 4.79813 17.5496 4.686L16.2773 4.0615C16.1949 4.02105 16.104 4 16.0119 4Z" fill="#00FFB0" fill-opacity="0.5" stroke="#00FFB0" />
      <path d="M10.2148 5.29527L6.50907 5.29628C6.15096 5.29628 6 5.82858 6 6.15425V7.00855C6 7.34578 6.31044 7.61433 6.68081 7.59749L12.3625 7.33914C12.443 7.33548 13.7047 7.33597 13.7812 7.35914C14.1657 7.47569 13.5741 7.87325 13.0572 8.196C12.9646 8.25384 12.8817 8.32593 12.8122 8.41017L11.5393 9.95363C11.5209 9.97595 11.5008 9.99705 11.4791 10.0168L8.59372 12.6407L6.20062 15.361C6.0742 15.5047 6.02881 15.6939 6.07789 15.8724L6.50907 17.4409C6.58861 17.7302 6.89316 17.92 7.21857 17.883L11.739 17.3691C11.8032 17.3618 11.8683 17.3633 11.9321 17.3735L16.6991 17.9733C17.0678 18.0325 17.6304 18.0169 17.6718 17.6785L17.996 16.2043C18.0355 15.8806 17.7798 15.397 17.4239 15.361L14.7538 15.2942H11.2257C11.0036 15.2942 10.8005 15.4084 10.7011 15.5891C10.6018 15.7698 10.3987 15.8839 10.1765 15.8839H9.80383C9.30941 15.8839 9.21301 15.4009 9.45312 15.0079L10.5033 13.2888C10.527 13.25 10.5555 13.2137 10.5882 13.1807L13.457 10.282L15.5899 7.78815C15.6784 7.68464 15.7265 7.55725 15.7265 7.42612V6.24994V6.24994C15.7265 6.20072 15.7265 6.1546 15.7265 6.10538V5.59011C15.7265 5.29527 15.6287 5.27969 15.336 5.29535L10.2148 5.29527Z" fill="#00FFB0" />
    </svg>
  );
};

export default Icon;
