import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.5 15.7499H9C9.4125 15.7499 9.75 16.0874 9.75 16.4999C9.75 16.9124 9.4125 17.2499 9 17.2499H1.5C1.0875 17.2499 0.75 16.9124 0.75 16.4999C0.75 16.0874 1.0875 15.7499 1.5 15.7499ZM3.93 6.05245L6.0525 3.92995L15.6 13.4774C16.185 14.0624 16.185 15.0149 15.6 15.5999C15.015 16.1849 14.0625 16.1849 13.4775 15.5999L3.93 6.05245ZM10.2975 1.80745L12.42 3.92995C13.005 4.51495 13.005 5.46745 12.42 6.05245L11.355 7.11745L7.1175 2.87245L8.175 1.81495C8.76 1.22245 9.7125 1.22245 10.2975 1.80745ZM2.8725 7.10995L7.1175 11.3549L6.06 12.4124C5.475 12.9974 4.5225 12.9974 3.9375 12.4124L1.815 10.2899C1.23 9.70495 1.23 8.75245 1.815 8.16745L2.8725 7.10995Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;