import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.99338 11.3503L3.14596 14.2314C2.95135 14.4379 2.95135 14.7581 3.14596 14.9646L5.99338 17.8457C6.3109 18.1762 6.864 17.9387 6.864 17.4843V15.6255H13.0198C13.5831 15.6255 14.044 15.1608 14.044 14.5928C14.044 14.0249 13.5831 13.5602 13.0198 13.5602H6.864V11.7117C6.864 11.247 6.3109 11.0198 5.99338 11.3503ZM20.8553 8.03541L18.0079 5.15427C17.6904 4.82382 17.1373 5.06133 17.1373 5.51571V7.36418H10.9713C10.4079 7.36418 9.947 7.82888 9.947 8.39684C9.947 8.96481 10.4079 9.42951 10.9713 9.42951H17.127V11.278C17.127 11.7427 17.6801 11.9699 17.9976 11.6394L20.8451 8.75828C21.0499 8.56207 21.0499 8.23162 20.8553 8.03541Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;