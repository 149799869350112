import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.5 6C16.5 5.5875 16.1625 5.25 15.75 5.25H15V3.75C15 2.925 14.325 2.25 13.5 2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V14.25C1.5 15.075 2.175 15.75 3 15.75H13.5C14.325 15.75 15 15.075 15 14.25V12.75H15.75C16.1625 12.75 16.5 12.4125 16.5 12C16.5 11.5875 16.1625 11.25 15.75 11.25H15V9.75H15.75C16.1625 9.75 16.5 9.4125 16.5 9C16.5 8.5875 16.1625 8.25 15.75 8.25H15V6.75H15.75C16.1625 6.75 16.5 6.4125 16.5 6ZM12.75 14.25H3.75C3.3375 14.25 3 13.9125 3 13.5V4.5C3 4.0875 3.3375 3.75 3.75 3.75H12.75C13.1625 3.75 13.5 4.0875 13.5 4.5V13.5C13.5 13.9125 13.1625 14.25 12.75 14.25ZM4.875 9.75H7.875C8.085 9.75 8.25 9.915 8.25 10.125V12.375C8.25 12.585 8.085 12.75 7.875 12.75H4.875C4.665 12.75 4.5 12.585 4.5 12.375V10.125C4.5 9.915 4.665 9.75 4.875 9.75ZM9.375 5.25H11.625C11.835 5.25 12 5.415 12 5.625V7.125C12 7.335 11.835 7.5 11.625 7.5H9.375C9.165 7.5 9 7.335 9 7.125V5.625C9 5.415 9.165 5.25 9.375 5.25ZM4.875 5.25H7.875C8.085 5.25 8.25 5.415 8.25 5.625V8.625C8.25 8.835 8.085 9 7.875 9H4.875C4.665 9 4.5 8.835 4.5 8.625V5.625C4.5 5.415 4.665 5.25 4.875 5.25ZM9.375 8.25H11.625C11.835 8.25 12 8.415 12 8.625V12.375C12 12.585 11.835 12.75 11.625 12.75H9.375C9.165 12.75 9 12.585 9 12.375V8.625C9 8.415 9.165 8.25 9.375 8.25Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;