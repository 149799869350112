import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.36247 2.54994L5.57247 7.10994C5.26497 7.60494 5.62497 8.24994 6.20997 8.24994H11.7825C12.3675 8.24994 12.7275 7.60494 12.42 7.10994L9.63747 2.54994C9.34497 2.06994 8.65497 2.06994 8.36247 2.54994Z" fill="#00FFB0"/>
            <path d="M13.125 16.5C14.989 16.5 16.5 14.989 16.5 13.125C16.5 11.261 14.989 9.75 13.125 9.75C11.261 9.75 9.75 11.261 9.75 13.125C9.75 14.989 11.261 16.5 13.125 16.5Z" fill="#00FFB0"/>
            <path d="M3 16.125H7.5C7.9125 16.125 8.25 15.7875 8.25 15.375V10.875C8.25 10.4625 7.9125 10.125 7.5 10.125H3C2.5875 10.125 2.25 10.4625 2.25 10.875V15.375C2.25 15.7875 2.5875 16.125 3 16.125Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;