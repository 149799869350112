import React from "react";

const Icon: React.FC = (props) => {
  return (
    <svg
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9796 1.36306C17.1034 0.442914 16.6494 0 15.989 0H10.871C10.2106 0 9.88036 0.219005 9.38506 0.709316L0.170885 12.6369C0.0752217 12.764 0.0171802 12.9148 0.00326418 13.0727C-0.0106518 13.2305 0.0201075 13.3891 0.0920955 13.5306C0.164084 13.6721 0.274456 13.791 0.410845 13.8739C0.547234 13.9568 0.704251 14.0005 0.864301 14H4.06887C4.22379 13.9997 4.3758 13.9583 4.50907 13.8801C4.64235 13.8019 4.75202 13.6898 4.82668 13.5554L5.78425 12.3117C5.85864 12.1771 5.96825 12.0648 6.10158 11.9866C6.23492 11.9083 6.38707 11.8671 6.54206 11.8671H9.59639C9.80328 11.8673 10.0033 11.9409 10.1599 12.0747C10.3166 12.2085 10.4195 12.3935 10.45 12.5961L10.5507 13.2711C10.5811 13.4736 10.684 13.6587 10.8407 13.7924C10.9974 13.9262 11.1973 13.9999 11.4042 14H13.6776C14.338 14 14.8333 13.4574 14.9984 12.6402C15.2642 11.3196 16.8541 2.28485 16.9796 1.36306ZM10.4169 8.88116C10.123 8.88116 9.83577 8.79489 9.59141 8.63326C9.34706 8.47163 9.15661 8.2419 9.04415 7.97313C8.93168 7.70435 8.90226 7.40859 8.95959 7.12326C9.01692 6.83793 9.15844 6.57583 9.36625 6.37012C9.57405 6.16441 9.83881 6.02431 10.127 5.96756C10.4153 5.9108 10.714 5.93993 10.9856 6.05126C11.2571 6.16259 11.4891 6.35113 11.6524 6.59302C11.8157 6.83491 11.9028 7.1193 11.9028 7.41022C11.903 7.60353 11.8648 7.79498 11.7902 7.97363C11.7156 8.15228 11.6062 8.31462 11.4682 8.45139C11.3302 8.58815 11.1663 8.69664 10.9859 8.77067C10.8056 8.84469 10.6122 8.88279 10.4169 8.88279V8.88116Z"
        fill="#00FFB0"
      />
    </svg>
  );
};

export default Icon;
