import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M15 3H3C2.1675 3 1.5075 3.6675 1.5075 4.5L1.5 13.5C1.5 14.3325 2.1675 15 3 15H15C15.8325 15 16.5 14.3325 16.5 13.5V4.5C16.5 3.6675 15.8325 3 15 3ZM15 12.75C15 13.1625 14.6625 13.5 14.25 13.5H3.75C3.3375 13.5 3 13.1625 3 12.75V5.25C3 4.8375 3.3375 4.5 3.75 4.5H14.25C14.6625 4.5 15 4.8375 15 5.25V12.75ZM10.5 7.5C10.9125 7.5 11.25 7.1625 11.25 6.75C11.25 6.3375 10.9125 6 10.5 6H9.75V5.9925C9.75 5.58 9.4125 5.2425 9 5.2425C8.5875 5.2425 8.25 5.58 8.25 5.9925V6H7.5C7.0875 6 6.75 6.3375 6.75 6.75V9C6.75 9.4125 7.0875 9.75 7.5 9.75H9.75V10.5H7.5C7.0875 10.5 6.75 10.8375 6.75 11.25C6.75 11.6625 7.0875 12 7.5 12H8.25C8.25 12.4125 8.5875 12.75 9 12.75C9.4125 12.75 9.75 12.4125 9.75 12H10.5C10.9125 12 11.25 11.6625 11.25 11.25V9C11.25 8.5875 10.9125 8.25 10.5 8.25H8.25V7.5H10.5Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;