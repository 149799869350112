import React from "react";

const Icon: React.FC = (props) => {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.505 11.265C8.7975 11.5575 9.27 11.5575 9.5625 11.265L14.3325 6.49497C14.625 6.20247 14.625 5.72997 14.3325 5.43747L10.62 1.72497C10.335 1.42497 9.8625 1.42497 9.57 1.71747L4.7925 6.49497C4.5 6.78747 4.5 7.25997 4.7925 7.55247L8.505 11.265ZM10.095 3.30747L12.75 5.96247L9.0375 9.67497L6.3825 7.01997L10.095 3.30747ZM15.3075 11.5575L13.7175 9.96747C13.5825 9.83247 13.3875 9.74997 13.1925 9.74997H12.99L11.49 11.25H12.9225L14.25 12.75H3.75L5.085 11.25H6.6225L5.1225 9.74997H4.8075C4.605 9.74997 4.4175 9.83247 4.275 9.96747L2.685 11.5575C2.4075 11.8425 2.25 12.225 2.25 12.6225V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.8325 15.75 15V12.6225C15.75 12.225 15.5925 11.8425 15.3075 11.5575Z" fill="#00FFB0"/>
        </svg>
    );
};

export default Icon;